
.sliding-text-wrapper{
    position: absolute;
    bottom: 0;
    line-height: 50vh;
    font-size: 50vh;
    white-space: nowrap;
    color: #ffffff09;
    width: 50%;
    font-weight: bold;
    display: table-cell;
    vertical-align: bottom;

}